import React from "react";

const WeatherDisplay = ({ weatherData }) => {
    if (!weatherData) {
        return <p className="text-muted">Žádná data k zobrazení.</p>;
    }

    const { name, main, weather, wind, sys } = weatherData;
    const isDay = weatherData.dt > sys.sunrise && weatherData.dt < sys.sunset;

    return (
        <div className="card bg-dark text-white">
            <div className="card-body">
                <h2 className="card-title">Počasí v {name}</h2>
                <h4 className="card-text">
                    {isDay ? "🌞" : "🌙"} Teplota: {Math.round(main.temp)}°C
                </h4>
                <p className="card-text">Podmínky: {weather[0].description.charAt(0).toUpperCase() + weather[0].description.slice(1)}</p>
                <p className="card-text">Rychlost větru: {wind.speed} m/s</p>
                <p className="card-text">Vlhkost: {main.humidity}%</p>
            </div>
        </div>
    );
};

export default WeatherDisplay;
