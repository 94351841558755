import React, { useState } from "react";

const WeatherForm = ({ onSearch }) => {
    const [city, setCity] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        if (city.trim()) {
            onSearch(city);
            setCity("");
        }
    };

    return (
        <form onSubmit={handleSubmit} className="d-flex justify-content-center mb-4">
            <input
                type="text"
                placeholder="Zadej město..."
                className="form-control me-2"
                value={city}
                onChange={(e) => setCity(e.target.value)}
            />
            <button type="submit" className="btn btn-primary">
                Hledat
            </button>
        </form>
    );
};

export default WeatherForm;
