import React from "react";

const WeeklyForecast = ({ forecastData }) => {
    return (
        <div className="mt-4">
            <h3>Týdenní předpověď</h3>
            <div className="row">
                {forecastData.map((day, index) => (
                    <div key={index} className="col-md-4 mb-3">
                        <div className="card bg-dark text-white">
                            <div className="card-body">
                                <h5 className="card-title">
                                    {new Date(day.dt * 1000).toLocaleDateString("cs-CZ", {
                                        weekday: "long",
                                        day: "numeric",
                                        month: "numeric",
                                    })}
                                </h5>
                                <p className="card-text">
                                    🌡️ Denní teplota: {Math.round(day.temp.day)}°C
                                </p>
                                <p className="card-text">
                                    🌡️ Noční teplota: {Math.round(day.temp.night)}°C
                                </p>
                                <p className="card-text">
                                    ☁️ Podmínky: {day.weather[0].description}
                                </p>
                                <p className="card-text">
                                    💨 Vítr: {day.wind_speed} m/s
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default WeeklyForecast;
