import React, { useState, useEffect } from "react";
import WeatherForm from "./components/WeatherForm";
import WeatherDisplay from "./components/WeatherDisplay";
import WeeklyForecast from "./components/WeeklyForecast";
import "./App.css";

const App = () => {
  const [weatherData, setWeatherData] = useState(null);
  const [forecastData, setForecastData] = useState(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            fetchWeatherByCoords(latitude, longitude);
            fetchWeeklyForecast(latitude, longitude);
          },
          (error) => {
            console.error("Chyba při získávání polohy:", error);
          }
      );
    }
  }, []);

  const fetchWeather = async (city) => {
    const apiKey = "754cabe7f267442552f1c7792a1476f1";
    const url = `https://api.openweathermap.org/data/2.5/weather?q=${city}&units=metric&lang=cz&appid=${apiKey}`;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Město nenalezeno");
      }
      const data = await response.json();
      setWeatherData(data);
    } catch (error) {
      alert(error.message);
    }
  };

  const fetchWeatherByCoords = async (lat, lon) => {
    const apiKey = "754cabe7f267442552f1c7792a1476f1";
    const url = `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&units=metric&lang=cz&appid=${apiKey}`;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Nepodařilo se získat data podle polohy");
      }
      const data = await response.json();
      setWeatherData(data);
    } catch (error) {
      alert(error.message);
    }
  };

  const fetchWeeklyForecast = async (lat, lon) => {
    const apiKey = "754cabe7f267442552f1c7792a1476f1";
    const url = `https://api.openweathermap.org/data/2.5/onecall?lat=${lat}&lon=${lon}&exclude=current,minutely,hourly,alerts&units=metric&lang=cz&appid=${apiKey}`;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        //throw new Error("Nepodařilo se získat předpověď");
      }
      const data = await response.json();
      setForecastData(data.daily);
    } catch (error) {
      alert(error.message);
    }
  };

  const getBackgroundStyle = () => {
    if (!weatherData) return { backgroundColor: "#667eea" };

    const weather = weatherData.weather[0].main.toLowerCase();
    const isDay = weatherData.dt > weatherData.sys.sunrise && weatherData.dt < weatherData.sys.sunset;

    if (weather.includes("clear")) {
      return {
        backgroundImage: isDay
            ? "url('https://images.pexels.com/photos/96622/pexels-photo-96622.jpeg')"
            : "url('https://images.pexels.com/photos/176851/pexels-photo-176851.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')",
      };
    }
    if (weather.includes("clouds")) {
      return {
        backgroundImage: "url('https://images.pexels.com/photos/158163/clouds-cloudporn-weather-lookup-158163.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260')",
      };
    }
    if (weather.includes("rain")) {
      return {
        backgroundImage: "url('https://images.pexels.com/photos/110874/pexels-photo-110874.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260')",
      };
    }
    if (weather.includes("snow")) {
      return {
        backgroundImage: "url('https://images.pexels.com/photos/3462588/pexels-photo-3462588.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')",
      };
    }
    if (weather.includes("mist")) {
      return {
        backgroundImage: "url('https://images.pexels.com/photos/395198/pexels-photo-395198.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')",
      };
    }

    return { background: "linear-gradient(to right, #667eea, #764ba2)" };
  };


  return (
      <div className="container-fluid text-white min-vh-100" style={getBackgroundStyle()}>
        <div className="row justify-content-center">
          <div className="col-md-6 text-center">
            <h1 className="my-4">Weather App</h1>
            <WeatherForm onSearch={fetchWeather} />
            <WeatherDisplay weatherData={weatherData} />
            {forecastData && <WeeklyForecast forecastData={forecastData} />}
          </div>
        </div>
      </div>
  );
};

export default App;